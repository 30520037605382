.sp-hero {
  border-bottom: 2px solid $secondary-color;
  min-height: 205px;
  @media only screen and (min-width: 375px) {
    min-height: 243px;
  }
  @media only screen and (min-width: 425px) {
    min-height: 273px;
  }
  @media only screen and (min-width: 768px) {
    min-height: 493px;
  }
}
