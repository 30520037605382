.sp-reasons {
  &__content {
    margin-top: 60px;
    @media only screen and (min-width: 768px) {
      margin-top: 40px;
      display: grid;
      grid-gap: 24px;
      grid-template-columns: repeat(2, [ col-start] minmax(0, 1fr));
    }
  }

  &__block {
    display: flex;
    gap: 15px;
    margin: 0 0 2.5em;
    padding: 1.5em;
    border-bottom: 1px solid #ffa235;
    background: #2d2d2d;
    @media only screen and (min-width: 768px) {
      border-bottom: none;
      margin: 0;
    }
  }

  .sp-content-title {
    font-size: 22px;
  }
}
