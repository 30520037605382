.sp-header {
  font-weight: 400;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 68px;
  position: relative;
  @media only screen and (min-width: 480px) {
    padding-top: 75px;
  }

  h1 {
    font-size: 6.8vw;
    color: #000000d1;
    font-weight: 900;
    margin: 0;
  }
  h4 {
    font-size: 3.8vw;
    margin: 0;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 400;
    letter-spacing: 12px;
    text-align: center;
  }

  &__title {
    display: flex;
    background-color: rgba(255, 255, 255, 0.3294117647);
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    gap: 0.5vh;
    position: absolute;
    z-index: 80;
    padding: 1.5vh 5vw;
    bottom: 2px;
    width: 100%;
    background: linear-gradient(0deg, #ffa235b3, transparent);
    text-shadow: 0px 0px 7px #ffa235;
  }
}

.sp-socials {
  display: flex;
  gap: 25px;
  a {
    & svg {
      transition: 0.2s all ease-in-out;
      color: #000000;
    }
    &:hover svg {
      transition: 0.2s all ease-in-out;
      color: #ffefe2;
      transform: scale(130%);
    }
  }
}
