.sp-artists {
  margin: 0 auto 60px;

  &__container {
    margin-top: 2.5em;
    background: $secondary-background-color;
    padding: 2em;
  }

  &__wrapper {
    margin-top: 2.5em;
  }

  &__image {
    &:nth-child(2n + 1) {
      margin-bottom: 20px;
    }

    &:nth-child(2n) {
      margin-top: 20px;
    }
  }
  &__artist {
    margin-bottom: 40px;
    padding: 0 0 40px 0;
    border-bottom: 1px solid #ffa135;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-direction: column;
    &:last-child {
      border-bottom: none;
    }
    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__image {
    @media only screen and (min-width: 768px) {
      min-width: 30%;
    }
  }

  &__job {
    margin-top: 60px;
    .sp-content-title {
      border: 1px solid #fff;
      gap: 10px;
      padding: 8px 10px;
      margin-top: 40px;
    }
  }

  &__job-title {
    font-size: 26px;
    display: block;
    text-align: center;
    margin-bottom: 20px;
    @media only screen and (min-width: 768px) {
      font-size: 34px;
    }
  }
  &__job-description {
    @media only screen and (min-width: 768px) {
      grid-gap: 24px;
      display: grid;
      grid-template-columns: repeat(2, [col-start] minmax(0, 1fr));
    }
    h5 {
      font-weight: 400;
      font-size: 1.125em;
    }
    li {
      margin-bottom: 15px;
    }
  }

  .job-button-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    margin: 30px 0 20px;
  }

  .job-button {
    text-decoration: none;
    display: flex;
    color: #333;
    border: 1px solid #ffa135;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #ffa135;
    gap: 10px;
    &:hover {
      background-color: $orange-juice;
      transition: all 0.3s;
    }
  }

  &__context {
    h4 {
      margin-bottom: 10px;
    }
  }
}
