.sp-footer {
  border-top: 1px solid $secondary-color;
  display: flex;
  min-height: 125px;
  justify-content: center;
  align-items: center;
  text-align: center;
  & a {
    color: $secondary-color;
    display: block;
  }
}

.sp-impressions-layer {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2d2d2dd9;
  z-index: 110;
  backdrop-filter: blur(9px);
  overflow: hidden;
  padding-bottom: 5em;

  & h2 {
    padding: 25px 35px;
    border-bottom: 1px solid $secondary-color;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }

  &__close {
    margin-left: auto;
    cursor: pointer;
  }

  &__content {
    overflow-y: scroll;
    height: 100%;
    padding: 2em 2.5em;
  }

  &__block {
    margin-bottom: 40px;
  }

  &__privacy-policy {
    h5 {
      font-weight: 400;
      font-size: 1.125em;
    }
    li {
      margin-bottom: 15px;
    }
  }
}
