body {
  background-color: #212121;
  color: #fff;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

p,
a {
  line-height: 1.85;
  font-size: 1.125em;
}

.st-padding {
  padding: 0 2em;
}

.sector-separator {
  margin: 30px 0 20px 0;
  @media only screen and (min-width: 768px) {
    margin: 60px 0 50px 0;
  }
}

.sp-huge-title {
  font-size: 19.5vw;
  font-weight: 800;
  line-height: 130px;
  position: relative;
  color: #86879f78;
  @media only screen and (min-width: 768px) {
    font-size: 165px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 180px;
  }
}

.sp-huge-subtitle {
  font-size: 24px;
  font-weight: 300;
  color: #fff;
  top: 70px;
  position: absolute;
  left: 18%;
  letter-spacing: -1px;
  line-height: 1;
  @media only screen and (min-width: 480px) {
    font-size: 26px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 32px;
    top: 80px;
    line-height: 1.3;
  }
}

.sp-content-title {
  font-weight: 300;
  font-size: 20px;
  display: flex;
  margin-bottom: 15px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.text-highlight {
  color: $secondary-color;
}

.sp-flex-icon {
  display: flex;
}

main {
  max-width: 1280px;
  margin: 0 auto;
}
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}
//RESET CSS
/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin-block-end: 0;
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ol,
ul {
  list-style: none;
  padding: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}
img {
  width: 100%;
  height: auto;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

main p {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

@supports (padding: env(safe-area-inset-top)) {
  .sp-main,
  .sp-impressions-layer__block {
    padding-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
  }
}
