.main-slider {
  img {
    cursor: pointer;
    width: 100%;
    user-select: none;
  }
}

.swiper-button-next,
.swiper-button-prev {
  background: #2d2d2d !important;
  padding: 1em !important;
  border-radius: 50% !important;
  width: 42px !important;
  height: 42px !important;
  color: #f39b36 !important;
  &::after {
    font-size: 22px !important;
  }
}

.swiper-pagination-progressbar {
  background-color: #684a28 !important;
}
