.sp-seo-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
  &__image {
    z-index: 15;

    @media only screen and (min-width: 768px) {
      width: 33%;
    }
  }

  &__content {
    z-index: 15;
    width: 86%;
    left: 14%;
    position: relative;
    margin-top: 20px;
    @media only screen and (min-width: 480px) {
      width: 80%;
      left: 20%;
    }
    @media only screen and (min-width: 768px) {
      left: 0;
      margin-top: 80px;
    }
  }

  &__backdrop {
    width: 86%;
    height: 100%;
    z-index: 10;
    position: absolute;
    background-color: #2d2d2d;
    right: 0;
    top: 40px;

    @media only screen and (min-width: 480px) {
      width: 80%;
    }
  }
}
