.sp-navigation {
  position: fixed;
  display: flex;
  justify-content: center;
  gap: 15px;
  background-color: $global-color;
  padding: 23px 0 22px;
  width: 100%;
  z-index: 100;
  box-shadow: 0 0px 8px 3px #000;
  border-bottom: 1px solid #171717;
  @media only screen and (min-width: 480px) {
    gap: 20px;
  }

  a {
    text-decoration: none;
    color: $secondary-color;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 12px;
    @media only screen and (min-width: 480px) {
      font-size: 1rem;
    }
  }

  & .active {
    color: #fff;
    font-weight: 400;
  }
}
