.sp-tattoos {
  margin: 120px 0 50px;
  @media only screen and (min-width: 768px) {
    margin: 120px 0 90px 0;
  }
  &__content {
    background: $secondary-background-color;
    padding: 2em;
    width: 85%;

    @media only screen and (min-width: 480px) {
      width: 80%;
    }
  }

  &__slider {
    background-color: $secondary-background-color;
  }
}
