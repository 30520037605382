.sp-contact {
  &__title {
    margin-bottom: 20px !important;
  }

  .sp-content-title {
    margin-bottom: 0;
  }

  &__content {
    margin: 50px auto 0;
    width: 85%;
    padding: 2em;
    background-color: $secondary-background-color;
  }

  &__call-to-action {
    margin-top: 50px;
    div {
      margin-bottom: 30px;
    }
    & .sp-content-title {
      margin-bottom: 5px;
    }

    & .text-highlight {
      margin-top: 10px;
    }

    .sp-socials {
      justify-content: center;
      margin-bottom: 10px;
      margin-top: 50px;
    }
  }

  .sp-socials {
    a svg {
      color: $secondary-color;
    }
    a:hover {
      svg {
        color: #fff;
      }
    }
  }

  .punchline {
    text-align: center;
    font-size: 28px;
  }
}
