.sp-studio {
  margin: 0 0 30px 0;
  &__content {
    background-color: $secondary-background-color;
    padding: 2em;
    width: 86%;
    left: 14%;
    position: relative;
    @media only screen and (min-width: 480px) {
      width: 80%;
      left: 20%;
    }
  }
}
